﻿/* Colours */
$linkcolour: #008676;
$link-hover-colour: #1b1d1d;
$linkactivecolour: #008676;
$linkinactivecolour: #666;
$linkvisitedcolour: #1B1D1D;
$fontcolour: #5B6770;
$dangerbackground: #F11F44;
$pagefilterbackground: #393E44;
$footer-subscribe: #AAD1D7;
$footer-subscribe-text: #59A5B1;

$corporate-hq-light-grey: #D0D3D4;
$corporate-light-grey: lighten($corporate-hq-light-grey, 10%);
$corporate-dark-grey: #5b6770;

$button-background: #00C7B1;
$button-foreground: #1B1D1D;
$button-hover-background:#00E0C6;
$button-active-background:#008676;
$button-active-foreground:#FFFFFF;
$button-inactive-background:#D0D3D4;

/* Colours - pro */
$pro-primary: #FFF;
$pro-secondary: #B4D8DE;
$pro-tertiary: #D6F0F4;

/* Colours - home */
$home-primary: #FFF;
$home-secondary: #B4D8DE;
$home-tertiary: #D6F0F4;
$linkcolour-home: #12cdb5;

/* Colours - Led Inspire */
$led-green: #A2D874;
$led-pink: #E10098;
$pink: #E10098;
$pink-hover: #ff39bf;
$pink-active: #c60086;
$green: #A2D874;
$green-hover: #8ace50;
$green-active: #65a62e;

/*case studies*/
$heroquotes-aqua:#00C7B1;
$grey-border: #707070;
$cs-font: 'Helvetica Neue';
/* Colours - forms */
$form-field-red-background: #FA5858;
$form-field-red-foreground: #3E0202;
$form-field-green-background: #7BAD44;
$form-field-green-foreground: #0E2E1D;

/* Dimensions */
$footer-height: 800px;
$full-menu-height: 60px;

/* Transitions */
$filtertransition: left 0.5s;

/* MvcNotify */
$errorColourBackground: #ff8e8f;
$errorColourText: #270000;
$successColourBackground: #93d6b1;
$successColourText: #000;
$infoColourBackground: #84c4d7;
$infoColourText: #001217;
$warningColourBackground: #ffdf82;
$warningColourText: #3A2D01;

/* Official brand colours */
$opt-primary: #E4002B;
$opt-secondary-black: #2D2926;
$opt-secondary-grey: #5B6770;
$opt-secondary-white: #FFF;

$opt-tertiary-blue: #009CDE;
$opt-tertiary-green: #78BE20;
$opt-tertiary-light-blue: #71C5E8;
$opt-tertiary-purple: #4C4084;
$opt-tertiary-darkblue1: #325a8b;
$opt-tertiary-orange: #F2A900;
$opt-tertiary-darkblue2: #004B87;
$opt-tertiary-green: #00C7B1;
$opt-tertiary-pink: #E10098;
$opt-tertiary-grey: #D0D3D4;
$opt-tertiary-dark-grey: #8D9093;